import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  message,
  Spin,
} from "antd";
import moment from "moment";
import { updateCasePatientDetails, getCaseDetails } from "../../../api/CasesAPI";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  background: white;
`;

const StyledButtonFormItem = styled(Form.Item)`
  width: fit-content;
  margin-left: auto;
  .ant-btn-lg {
    margin-left: 20px;
  }
`;

const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-foem-item {
    width: 45%;
  }
`;

const CasePatientEditDetails = ({
  caseId,
  viewPatientEditModal,
  closePatientEditModal,
}) => {
  const [form] = Form.useForm();
  const [intialPatientValues, setIntialPatientValues] = useState();
  const [submitButttonLoading, setSubmitButtonLoading] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  const [intialLoading, setIntialLoading] = useState(false);

  const updatePatientDetails = (values) => {
    if (!_.isEqual(values, intialPatientValues)) {
      setSubmitButtonLoading(true);
      const updateData = {
        address1: values.address1,
        address2: values.address2,
        age: values.age,
        dob: values.dob.toISOString(),
        phone: values.phone.toString(),
        state: values.state,
        city: values.city,
        zipCode: values.zipCode.toString(),
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      };

      updateCasePatientDetails(caseId, updateData)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            message.success("Patient details updated successfully !");
            setSubmitButtonLoading(false);
            closePatientEditModal(false);
            form.resetFields();
          } else {
            message.error(data.payload);
            setSubmitButtonLoading(false);
          }
        })
        .catch((error) => {
          const { response } = error;
          setSubmitButtonLoading(false);
          if (response) {
            const { data } = response;
            if (data && data.message) {
              message.error(data.message);
            } else {
              message.error(
                "Unable to update Patient details, Please try again!"
              );
            }
          } else {
            message.error(
              "Unable to update Patient details, Please try again!"
            );
          }
        });
    } else {
      message.info("Please update value before submit !");
    }
  };

  useEffect(() => {
    if (caseId) {
      fetchCaseDetails(caseId);
    }
  }, [caseId]);

  useEffect(() => {
    if (caseDetails) {
      loadEditFormData(caseDetails.patient);
    }// eslint-disable-next-line
  }, [caseDetails]);

  function loadEditFormData(patient) {
    if (patient) {
      const data = {
        address1: patient.address1,
        address2: patient.address2,
        age: patient.age,
        dob: moment.utc(patient.dob),
        phone: patient.phone,
        city: patient.city,
        state: patient.state,
        zipCode: patient.zipCode,
        email: patient.email,
        firstName: patient.firstName,
        lastName: patient.lastName,
      };

      setIntialPatientValues({...data});

      let patientAge = null;
      if (!patient.age) {
        if (patient.dob) {
          patientAge = moment().diff(moment(patient.dob), "year");
          data.age = patientAge;
        }
      }

      form.setFieldsValue({...data});
      closePatientEditModal(true);
    }
  }

  const fetchCaseDetails = (caseId) => {
    setIntialLoading(true);
    getCaseDetails(caseId)
      .then((res) => {
        if (res.data && res.data.payload) {
          const caseData = res.data.payload[0];
          setCaseDetails(caseData);
          message.success("Successfully loaded Patient Details");
        } else {
          message.error("Unable to load Patient details. Please try again!");
        }
        setIntialLoading(false);
      })
      .catch(() => {
        message.error("Unable to load Patient details. Please try again!");
        setIntialLoading(false);
      });
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  function setPatientAge(date) {
    let patientAge = moment().diff(date, "year");
    form.setFieldsValue({ age: patientAge });
  }
  return (
    <Modal
      title={"Edit Patient Data"}
      open={viewPatientEditModal}
      onOk={""}
      onCancel={() => closePatientEditModal(false)}
      footer={[]}
    >
      <Spin spinning={intialLoading} indicator={<LoadingOutlined />}>
        <Form
          form={form}
          layout={"vertical"}
          name="patient-edit-modal"
          onFinish={updatePatientDetails}
        >
          <StyledFlexDiv>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "'First Name' required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "'Last Name' required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </StyledFlexDiv>
          <Form.Item
            name="address1"
            label="First Address"
            rules={[
              {
                required: true,
                message: "'First Address' required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="address2" label="Second Address">
            <Input />
          </Form.Item>

          <StyledFlexDiv>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </StyledFlexDiv>

          <Form.Item
            name="dob"
            label="DOB"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledDatePicker
              onChange={setPatientAge}
              disabledDate={disabledDate}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
          <StyledFlexDiv>
            <Form.Item
              name="age"
              label="Age"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <StyledInputNumber disabled={true} />
            </Form.Item>
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <StyledInputNumber />
            </Form.Item>
          </StyledFlexDiv>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledInputNumber />
          </Form.Item>

          <StyledButtonFormItem>
            <Button
              size={"large"}
              onClick={() => closePatientEditModal(false)}
              disabled={submitButttonLoading}
            >
              cancel
            </Button>
            <Button
              type="primary"
              size={"large"}
              loading={submitButttonLoading}
              htmlType="submit"
            >
              submit
            </Button>
          </StyledButtonFormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CasePatientEditDetails;
